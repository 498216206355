import React, { useState } from "react";
import firebase from '../../util/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'
function Form() {
    const [title, setTitle] = useState('');

    const handleOnChange = (e) => {
        setTitle(e.target.value);
    }

    const createTodo = () => {
        const todoRef = firebase.database().ref('crud-todo');
        const todo = {
            title,
            complete: false
        }

        todoRef.push(todo)
        title = ""
    }

    

    return (
        <>
            <div className="flex justify-center w-screen" >
                <input type="text" onChange={handleOnChange} value={title}  className="bg-slate-300 p-2 m-2 " placeholder="Item" />
                <button onClick={createTodo}  className="flex bg-cyan-400 w-20 items-center justify-between p-1 rounded-xl hover:bg-cyan-200"> Add Todo
                <FontAwesomeIcon icon={faBell} color="black"  />
                </button>
            </div>
        </>
    )
}

export default Form
